body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.absoluteCenter {
  position: "absolute";
  top: "50%";
  left: "50%";
  transform: translate("-50%", "-50%");
}

.rdp {
  height: 330px;
}

.fadeColor {
  transition: 0.5s ease-in-out background-color;
}

.backgroundGradient {
  background: linear-gradient(#fff, #e4ebf5);
}

.fallbackButton {
  display: block;
  text-decoration: none;
  padding: 8px 20px;
  border-radius: 12px;
  text-align: center;
  font-size: 14px;
}

.fallbackButton.primary {
  background-color: #49beef;
  color: #fff;
}

.fallbackButton.secondary {
  border: 1px solid #49beef;
  color: #49beef;
}

.recharts-wrapper {
  max-width: 100%;
  overflow: hidden;
}

.printBreakAlways {
  page-break-before: always;
}
