@font-face {
  font-family: "regular";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Montserrat-Regular.ttf"); /* IE9 Compat Modes */
}
@font-face {
  font-family: "bold";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Montserrat-Bold.ttf"); /* IE9 Compat Modes */
}
@font-face {
  font-family: "medium";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Montserrat-Medium.ttf"); /* IE9 Compat Modes */
}
@font-face {
  font-family: "semi";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Montserrat-SemiBold.ttf"); /* IE9 Compat Modes */
}
.absoluteCenter {
  position: "absolute";
  top: "50%";
  left: "50%";
  transform: translate("-50%", "-50%");
}
.headline {
  font-family: "bold";
  font-size: "20pt";
  color: "#49beef";
}
.subheadline {
  font-family: "bold";
  font-size: "16pt";
  color: "#2D3B51";
}
.bodytext {
  font-family: "medium";
  font-size: "13pt";
  color: "#2D3B51";
}
.dialogDivideRow {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.wooble {
  transition: 0.3s ease;
  animation: attention 4s 4;
}
.notification {
  transition: 0.3s ease;
  animation: bump 4s infinite;
}
.vertical {
  display: flex;
  flex-direction: column;
}
.horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.marker {
  background-size: contain;
  background-repeat: no-repeat;
  width: 30px;
  height: 40px;
  cursor: pointer;
}
.location {
  background-image: url("./assets/images/markers/location.svg");
}
.delivery {
  background-image: url("./assets/images/markers/delivery.svg");
}
.pickup {
  background-image: url("./assets/images/markers/pickup.svg");
}
.end {
  background-image: url("./assets/images/markers/endText.svg");
}
.start {
  background-image: url("./assets/images/markers/startText.svg");
}
.driver {
  background-image: url("./assets/images/car.svg");
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-family: "bold";
}

tr {
  font-family: "regular";
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
  font-family: "regular";
}

@keyframes attention {
  0% {
    border: 0px;
  }
  50% {
    border: 2px solid #49beef;
  }
  100% {
    border: 0px;
  }
}

@keyframes bump {
  0% {
    border: 2px solid white;
  }
  50% {
    border: 4px solid #49beef;
  }
  100% {
    border: 2px solid white;
  }
}

.WPContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.WPContent > p {
  display: block;
  overflow: hidden;
  margin: 0;
  flex: 1;
}
